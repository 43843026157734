import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FontAwesome from 'react-fontawesome';
import { useAuth } from '@/helpers/userContext';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  const { checkPerm } = useAuth();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(mainPathSplit.length > 2 ? mainPathSplit : ['home']);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    {
      key: 'home',
      label: (
        <NavLink onClick={toggleCollapsed} to="/">
          {t('menu.home')}
        </NavLink>
      ),
      icon: (
        <NavLink className="menuItem-iocn" to="/">
          <FeatherIcon icon="home" />
        </NavLink>
      ),
    },
    {
      key: 'administrating',
      label: t('menu.administrating'),
      icon: (
        <NavLink className="menuItem-iocn">
          <FeatherIcon icon="star" />
        </NavLink>
      ),
      children: [
        {
          key: 'users',
          label: (
            <NavLink onClick={toggleCollapsed} to="/administrating/users">
              {t('user.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/administrating/users">
              <FeatherIcon icon="user" />
            </NavLink>
          ),
          permission: 'administrating.users.view',
        },
        {
          key: 'roles',
          label: (
            <NavLink onClick={toggleCollapsed} to="/administrating/roles">
              {t('role.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/administrating/roles">
              <FeatherIcon icon="flag" />
            </NavLink>
          ),
          permission: 'administrating.roles.view',
        },
      ],
    },
    {
      key: 'crm',
      label: t('menu.crm'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="users" />
        </NavLink>
      ),
      children: [
        {
          key: 'orders',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/orders">
              {t('orders.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/orders">
              <FeatherIcon icon="file-text" />
            </NavLink>
          ),
          permission: 'crm.orders.view',
        },
        {
          key: 'site-applications',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/site-applications">
              {t('site_applications.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/site-applications">
              <FeatherIcon icon="inbox" />
            </NavLink>
          ),
          permission: 'crm.site_applications.view',
        },
        {
          key: 'binotel-auto-reply-settings',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/binotel-auto-reply-settings">
              {t('binotel_auto_reply_settings.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/binotel-auto-reply-settings">
              <FeatherIcon icon="shuffle" />
            </NavLink>
          ),
          permission: 'crm.binotel_auto_reply_settings.view',
        },
        {
          key: 'promo-codes',
          label: (
            <NavLink onClick={toggleCollapsed} to="/crm/promo-codes">
              {t('promo_codes.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/crm/promo-codes">
              <FeatherIcon icon="gift" />
            </NavLink>
          ),
          permission: 'crm.promo_codes.view',
        },
      ],
    },
    {
      key: 'cms',
      label: t('menu.cms'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FeatherIcon icon="image" />
        </NavLink>
      ),
      children: [
        {
          key: 'categories',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/categories">
              {t('categories.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/categories">
              <FeatherIcon icon="git-branch" />
            </NavLink>
          ),
          permission: 'cms.categories.view',
        },
        {
          key: 'head-categories',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/head-categories">
              {t('head_categories.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/head-categories">
              <FeatherIcon icon="settings" />
            </NavLink>
          ),
          permission: 'cms.header_categories.view',
        },
        {
          key: 'recommended-lists',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/recommended-lists">
              {t('recommended_products.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/recommended-lists">
              <FeatherIcon icon="thumbs-up" />
            </NavLink>
          ),
          permission: 'cms.recommended_products.view',
        },
        {
          key: 'products',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/products">
              {t('products.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/products">
              <FeatherIcon icon="shopping-bag" />
            </NavLink>
          ),
          permission: 'cms.products.view',
        },
        {
          key: 'cities',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/cities">
              {t('cities.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/cities">
              <FeatherIcon icon="navigation" />
            </NavLink>
          ),
          permission: 'cms.cities.view',
        },
        {
          key: 'stores',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/stores">
              {t('stores.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/stores">
              <FeatherIcon icon="shopping-cart" />
            </NavLink>
          ),
          permission: 'cms.stores.view',
        },
        {
          key: 'main-page',
          label: t('menu.main_page'),
          icon: (
            <div style={{ paddingLeft: 10 }}>
              <FeatherIcon icon="play" />
            </div>
          ),
          children: [
            {
              key: 'slider-elements',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/slider-elements">
                  {t('slider_elements.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/slider-elements">
                  <FeatherIcon icon="sliders" />
                </NavLink>
              ),
              permission: 'cms.main_page.slider_elements.view',
            },
            {
              key: 'carousel',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/carousel">
                  {t('main_page_carousel.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/carousel">
                  <FeatherIcon icon="image" />
                </NavLink>
              ),
              permission: 'cms.main_page.carousel.view',
            },
            {
              key: 'faqs',
              label: (
                <NavLink onClick={toggleCollapsed} to="/cms/main-page/faqs">
                  {t('faqs.menu_name')}
                </NavLink>
              ),
              icon: (
                <NavLink className="menuItem-iocn" to="/cms/main-page/faqs">
                  <FeatherIcon icon="help-circle" />
                </NavLink>
              ),
            },
          ],
        },
        {
          key: 'default-packing',
          label: (
            <NavLink onClick={toggleCollapsed} to="/cms/default-packing">
              {t('default_packing.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/cms/default-packing">
              <FeatherIcon icon="package" />
            </NavLink>
          ),
          permission: 'cms.default_packing.view',
        },
      ],
    },
    {
      key: 'accounting',
      label: t('menu.accounting'),
      icon: (
        <NavLink onClick={toggleCollapsed}>
          <FontAwesome name="money" />
        </NavLink>
      ),
      children: [
        {
          key: 'fops',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/fops">
              {t('fops.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/fops">
              <FeatherIcon icon="user-check" />
            </NavLink>
          ),
          permission: 'accounting.fops.view',
        },
        {
          key: 'invoices',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/invoices">
              {t('invoices.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/invoices">
              <FeatherIcon icon="tag" />
            </NavLink>
          ),
          permission: 'accounting.invoices.view',
        },
        {
          key: 'payments',
          label: (
            <NavLink onClick={toggleCollapsed} to="/accounting/payments">
              {t('payments.menu_name')}
            </NavLink>
          ),
          icon: (
            <NavLink className="menuItem-iocn" to="/accounting/payments">
              <FeatherIcon icon="tag" />
            </NavLink>
          ),
          permission: 'accounting.payments.view',
        },
      ],
    },
    {
      key: 'statistics',
      label: (
        <NavLink onClick={toggleCollapsed} to="/statistics">
          {t('menu.statistics')}
        </NavLink>
      ),
      icon: (
        <NavLink className="menuItem-iocn" to="/statistics">
          <FeatherIcon icon="bar-chart-2" />
        </NavLink>
      ),
    },
  ];

  const checkItem = (item) => {
    if (item.children) {
      // eslint-disable-next-line no-return-assign
      return (item.children = item.children.filter(checkItem)).length;
    }

    return !item.permission || checkPerm(item.permission);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode="inline"
      defaultSelectedKeys={mainPathSplit.length === 1 ? ['home'] : mainPathSplit}
      defaultOpenKeys={mainPathSplit.length > 2 ? mainPathSplit : ['home']}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      items={items.filter(checkItem)}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
